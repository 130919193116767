import React, { useState } from "react";
import "./LanguageDropdown.scss";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import { useTranslation } from "react-i18next";
import useOutsideClick from "../hooks/useOutsideClick";
import Icons, { TIcons } from "../assets";

interface ILanguageOption {
  code: string;
  icon: TIcons;
}

const languageOptions: ILanguageOption[] = [
  {
    code: "en",
    icon: "EnglandFlag",
  },
  {
    code: "pl",
    icon: "PolandFlag",
  },
  {
    code: "sk",
    icon: "SlovakiaFlag",
  },
];

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [language, setLanguage] = useState(i18n.resolvedLanguage);

  const triggerDropdown = () => setIsExpanded((prev) => !prev);

  const dropdownRef = useOutsideClick({
    shouldWatch: isExpanded,
    callback: triggerDropdown,
  });

  const listClassName = `berg-components-language-dropdown-list ${
    isExpanded ? "visible" : ""
  }`;

  const onLanguageSelect = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    triggerDropdown();
  };

  return (
    <div className="berg-components-language-dropdown" ref={dropdownRef}>
      <button
        type="button"
        aria-expanded={isExpanded ? "true" : "false"}
        onClick={triggerDropdown}
      >
        {languageOptions.map((flag, index) => {
          const Icon = Icons[flag.icon];
          return flag.code === language && <Icon key={index} />;
        })}
        {language && language.toUpperCase()}
        {isExpanded ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      <ul className={listClassName}>
        {languageOptions.map((option, index) => {
          const Icon = Icons[option.icon];
          const className = [
            "berg-components-language-dropdown-list-item",
            option.code === language ? "selected" : "",
          ].join(" ");

          return (
            <li
              className={className}
              key={index}
              onClick={() => onLanguageSelect(option.code)}
            >
              <Icon />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguageDropdown;
